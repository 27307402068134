import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Input, Button, Card } from 'antd';

const Weather = () => {
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState(null);

  const handleSearch = useCallback(async () => {
    const response = await axios.get(
      `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=14f889e59a44ff7e9e3eec6851587783&units=metric`
    );
    setWeather(response.data);
  }, [city]);

  return (
    <div>
      <h1>实时天气预报</h1>
      <Input value={city} onChange={(e) => setCity(e.target.value)} placeholder="请输入城市名称" />
      <Button onClick={handleSearch}>搜索</Button>
      {weather && (
        <Card title={`${weather.name} (${weather.sys.country})`}>
          <p>温度：{weather.main.temp}°C</p>
          <p>天气：{weather.weather[0].description}</p>
        </Card>
      )}
    </div>
  );
};

export default React.memo(Weather);
